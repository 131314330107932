import ErrorBoundary from "./ErrorBoundary";
import Layout from "./Layout";

export default function Wrapper() {
  return (
    <ErrorBoundary>
      <Layout />
    </ErrorBoundary>
  );
}
