import { Flex, Image, Text } from "@chakra-ui/react";

export default function () {
  return (
    <Flex mt="1.4rem" alignItems="center" justifyContent="center">
      <Text
        noOfLines={1}
        mr="0.8rem"
        fontSize={["x-small", "small"]}
        color="gray.400"
      >
        POWERED BY
      </Text>
      <Image
        h={["0.8rem"]}
        src="https://app.konnadex.com/background/logo.svg"
      />
    </Flex>
  );
}
