import router from "./router";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import chakraUItheme from "./theme/chakra";
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  return (
    <ChakraProvider theme={chakraUItheme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
}

export default App;
