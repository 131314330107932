import { Icon } from "@chakra-ui/react";
export function SuccessIcon(props: any) {
  return (
    <Icon
      viewBox="-47.41 -47.41 568.90 568.90"
      height="256px"
      width="256px"
      fill="none"
      {...props}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0">
        <path
          transform="translate(-47.41, -47.41), scale(17.778125)"
          d="M16,31.83539611659944C18.387125545666848,32.49821649064334,19.009055480414993,27.903331386681714,21.07090202974868,26.529836564695223C22.771934133070946,25.39669754098808,25.628862619403144,26.289542994420376,26.81932591011762,24.6281244989647C28.029172130546232,22.93965505773491,26.42391411980228,20.509295064167596,26.84263925854951,18.47476165593038C27.249707554665296,16.49686739727753,29.309632088410247,15.00073295446812,29.215880949860107,12.983561414877334C29.122335554051325,10.970816684483289,27.427681725376814,9.449583016443361,26.34422466616445,7.750756100112884C25.16071139782117,5.895044164877127,24.36654256305784,3.6622462917993945,22.513538688887763,2.474497545122844C20.622843139501818,1.2625889784794633,18.17380617764266,0.556325844343563,16.000000000000004,1.120257344096899C13.816193176894918,1.6867832237227958,12.90110593431566,4.375363894738619,10.933200860276685,5.478683174230223C9.12131060026053,6.494531693841938,6.484355433115852,5.766136494334359,5.043949369243537,7.262841174723439C3.6202918978465872,8.742142639882767,3.636871687395596,11.105977645835196,3.5437581050489637,13.156944072052228C3.458798429912774,15.028308451933512,4.216567962929001,16.77116156438428,4.528412482420842,18.61831499178294C4.858334705958914,20.572548360951636,3.8322147140010694,23.265349780429727,5.4448113700066525,24.41748204705381C7.282540255100706,25.730461791826784,10.277388225213459,22.747305575210806,12.12527380331174,24.045951853282517C14.528054771243848,25.73456428578921,13.170262314927351,31.049677998727255,16,31.83539611659944"
          fill="#6997f2"
          strokeWidth="0"
        />
      </g>

      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#CCCCCC"
        strokeWidth="7.58536"
      />

      <g id="SVGRepo_iconCarrier">
        <circle
          style={{ fill: "#ACDDDE" }}
          cx="237.042"
          cy="236.966"
          r="236.966"
        />
        <path
          style={{ fill: "#9ACA42" }}
          d="M237.016,474.085c100.781,0,186.811-62.944,221.074-151.635c-61.87-37.302-142.322-55-230.283-55 c-80.886,0-155.414,14.982-214.994,46.39C44.708,407.044,133.006,474.085,237.016,474.085z"
        />
        <path
          style={{ fill: "#9F7235" }}
          d="M239.725,181.075c-6.267,0-11.345,5.078-11.345,11.341v197.303c0,6.264,5.078,11.349,11.345,11.349 c6.267,0,11.341-5.085,11.341-11.349V192.416C251.066,186.156,245.993,181.075,239.725,181.075z"
        />
        <path
          style={{ fill: "#B28D5D" }}
          d="M239.725,181.075c-6.267,0-11.345,5.078-11.345,11.341v197.303c0,6.264,5.078,11.349,11.345,11.349 V181.075z"
        />
        <path
          style={{ fill: "#15AA63" }}
          d="M350.642,302.207c-6.297-10.327-98.708-122.37-98.708-122.37c-7.057-13.298-18.499-13.298-25.564,0 c0,0-92.167,110.565-98.704,122.37c-6.533,11.787-6.133,36.737,0,48.074c10.993,20.351,206.594,21.377,222.975,0 C358.578,339.924,356.943,312.534,350.642,302.207z"
        />
        <path
          style={{ fill: "#1EAE64" }}
          d="M323.365,233.265l-74.551-108.593c-5.336-10.035-13.979-10.035-19.315,0l-74.547,108.593 c-5.332,10.024-7.296,26.278-1.964,36.314h172.338C330.65,259.543,328.701,243.285,323.365,233.265z"
        />
        <path
          style={{ fill: "#28B467" }}
          d="M179.344,194.066c-3.794-7.128-3.794-18.675,0-25.803l52.953-99.594c3.787-7.136,9.923-7.136,13.71,0 l52.961,99.598c3.794,7.124,3.794,18.671,0,25.803H179.344V194.066z"
        />
      </g>
    </Icon>
  );
}
