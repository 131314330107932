import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { SuccessIcon } from "../../icons/SuccessIcon";
import { useEffect, useState } from "react";
import { STATUS } from "../../utils/constants";

export default function StatusPage() {
  const location = useLocation();
  const [, pathOrigin, pageType] = location.pathname.split("/");
  const merchant = location.state?.postpayAction;
  const navigate = useNavigate();
  const [redirectCount, setRedirectCount] = useState(5);
  useEffect(() => {
    if (redirectCount == 0) {
      window.location.href = location.state?.postpayAction?.redirectUrl;
    }
  }, [redirectCount]);

  useEffect(() => {
    const injectedOrigin = location.state?.origin;
    if (injectedOrigin !== pathOrigin) {
      navigate("/notfound");
    } else {
      if (location.state?.postpayAction?.redirect) {
        const intervalId = setInterval(() => {
          setRedirectCount((p) => p - 1);
        }, 1000);
        setTimeout(() => {
          clearInterval(intervalId);
        }, 5000);
      }
    }
  }, []);

  if (String(pageType).toLowerCase() == STATUS.SUCCESS) {
    return (
      <Box>
        <Flex
          flexDirection="column"
          justifyContent="center"
          height={"100vh"}
          alignItems="center"
        >
          <Text>
            {location.state?.postpayAction?.redirect
              ? `Redirect to ${location.state?.postpayAction?.redirectUrl} in ${redirectCount} seconds`
              : null}
          </Text>
          <Box>
            <SuccessIcon />
          </Box>
          <Box mt="20px" mb="20px">
            <Heading color="gray.500" size={"lg"}>
              {merchant?.successMessage
                ? merchant.successMessage
                : "Your payment is successful"}
            </Heading>
          </Box>
          <NavLink className="back-link" to={`/${pathOrigin}`}>
            Back to Payment Page
          </NavLink>
        </Flex>
      </Box>
    );
  }
  return <div></div>;
}
