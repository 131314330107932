import React from "react";
import { Button, Flex, Input } from "@chakra-ui/react";
import { CountrySelector, usePhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

interface ChakraPhoneProps {
  value: string;
  onChange: (arg: { target: { value: string; id: string } }) => void;
}

export const PhoneNumber: React.FC<ChakraPhoneProps> = ({
  value,
  onChange,
}) => {
  const phoneInput = usePhoneInput({
    defaultCountry: "ng",
    value,
    onChange: (data) => {
      onChange({ target: { value: data.phone, id: "phone" } });
    },
  });
  return (
    <Flex alignItems="center" width={"100%"}>
      <CountrySelector
        selectedCountry={phoneInput.country.iso2}
        onSelect={(country) => phoneInput.setCountry(country.iso2)}
        renderButtonWrapper={({ children, rootProps }) => (
          <Button {...rootProps} variant="outline" mr="8px">
            {children}
          </Button>
        )}
      />

      <Input
        id="phone"
        placeholder="Phone number"
        value={phoneInput.phone}
        onChange={phoneInput.handlePhoneValueChange}
        ref={phoneInput.inputRef}
      />
    </Flex>
  );
};
