import { ReactNode } from "react";
import useKonnadexCheckout from "./useKonnadexCheckout";
import { Config } from "./types";

export interface KonnadexCheckoutButtonProps extends Config {
  apiKey: string;
  text?: string;
  className?: string;
  children?: ReactNode;
  btnProps?: any;
  isDisabled: boolean;
  PayNowBtnComponent: any;
}

const KdxCheckoutBtn = (props: KonnadexCheckoutButtonProps): JSX.Element => {
  const {
    text,
    className,
    children,
    btnProps,
    isDisabled,
    PayNowBtnComponent,
    apiKey,
    ...restProps
  } = props;
  const [initializePayment, loaded] = useKonnadexCheckout({
    ...restProps,
    key: apiKey,
  });

  const handleInit = () => {
    if (loaded) {
      initializePayment();
    }
  };

  return (
    <PayNowBtnComponent
      {...(btnProps || {})}
      isDisabled={!loaded || isDisabled}
      className={className}
      onClick={handleInit}
    >
      {text || children}
    </PayNowBtnComponent>
  );
};

export default KdxCheckoutBtn;
