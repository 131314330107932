import { Outlet } from "react-router-dom";
import { useNavigation } from "react-router-dom";
import { AppLoader } from "../components/Loader";
export default function Layout() {
  const { state } = useNavigation();

  if (state === "loading") {
    return <AppLoader />;
  }
  return <Outlet />;
}
