import Wrapper from "./container/Wrapper";
import NotFound from "./pages/NotFound";
import PaynowPage from "./pages/PayNow";
import { Route, Routes, createBrowserRouter } from "react-router-dom";
import StatusPage from "./pages/StatusPage";

function RootRouter() {
  return (
    <>
      <Routes>
        <Route element={<Wrapper />}>
          <Route path=":paymentLink" element={<PaynowPage />}></Route>
          <Route path=":paymentLink/:status" element={<StatusPage />}></Route>
          <Route path="notfound" element={<NotFound />}></Route>
          <Route path="*" element={<NotFound />}></Route>
        </Route>
      </Routes>
      {/* {location.pathname === "/" && <Navigate replace={true} to="/" />} */}
    </>
  );
}
export default createBrowserRouter([{ path: "*", Component: RootRouter }]);
