import "./style.css";
export function AppLoader() {
  return (
    <div className="overlay">
      <div className="app-spinner-container">
        <div className="app-spinner">
          <svg
            width="30"
            height="30"
            viewBox="0 0 44 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.773438 27.0148V3.97296C0.773438 3.75857 0.9324 3.57742 1.14497 3.54957C9.59538 2.44231 14.9502 12.3499 9.39431 18.8127L1.96382 27.4561C1.5544 27.9323 0.773438 27.6428 0.773438 27.0148Z"
              fill="#1141D2"
            />
            <path
              d="M7.83847 25.7005L27.5516 5.19924C28.414 4.30238 29.6045 3.79553 30.8487 3.79553H39.9346C41.1413 3.79553 41.7605 5.24097 40.9279 6.11446L20.4604 27.5895L41.2653 49.1939C42.0439 50.0024 41.5753 51.3528 40.4632 51.5052L33.0139 52.5262C31.5814 52.7225 30.1404 52.2299 29.1278 51.1979L12.4039 34.1521V48.344C12.4039 49.2746 12.2521 50.2403 11.6352 50.937C9.15894 53.7335 3.88868 52.9906 1.88828 50.2545C1.46316 49.673 1.35092 48.9367 1.32567 48.2168L1.14925 43.1864C0.92151 36.6928 3.3349 30.3841 7.83847 25.7005Z"
              fill="#FF8300"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
