import { useParams } from "react-router-dom";
import { useRef, useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import KonnadexCheckoutButton from "../../konnadex-checkout/KdxCheckoutBtn";
import { formatCurrency, generateRandomReference } from "../../utils/helper";

import { MessageIcon, UserIcon, MoneyIcon } from "../../icons";
import { AppLoader } from "../../components/Loader";
import { Header } from "../../components/Header";
import { AddressIcon } from "../../icons/AddressIcon";
import { useNavigate } from "react-router-dom";
import { PhoneNumber } from "../../components/PhoneNumber";
import { PhoneNumberUtil } from "google-libphonenumber";
import Footer from "../../components/Footer";

const phoneUtil = PhoneNumberUtil.getInstance();
const FIAT_CURRENCY = [
  { value: "NGN", label: "Nigerian Naira" },
  { value: "KES", label: "Kenya Shellings" },
  { value: "USD", label: "US Dollar" },
  { value: "EUR", label: "Euro" },
  { value: "GBP", label: "British Pound" },
  { value: "GHS", label: "Ghanaian cedi" },
  { value: "AUD", label: "Australian dollar" },
  { value: "JPY", label: "Japanese yen" },
];
const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const REQUIRED_FIELDS = ["email", "fullname"];
const filterMetadata = (obj: any) => {
  const needed = ["fullname", "email", "phone", "address"];
  return needed.reduce((p, c) => ({ ...p, [c]: obj[c] }), {}) as any;
};
export default function () {
  const navigate = useNavigate();

  const params = useParams();
  const [paymentlinkInfo, setPaymentlinkInfo] = useState<any>();
  const [loading, setIsLoading] = useState(true);
  const [formdata, setFormData] = useState<any>({ phone: "+1204" });
  const merchantReference = useRef(generateRandomReference());
  const [errors, setErrors] = useState<any>({
    fullname: "",
    email: "",
    phone: "",
    address: "",
    fiatAmount: "",
    fiatCurrency: "",
  });

  const [disableBtn, setDisableBtn] = useState(true);

  useEffect(() => {
    if (params.paymentLink) {
      const baseUrl =
        params.paymentLink.split("_").length > 1
          ? "https://staging.konnadex.com"
          : "https://api.konnadex.com";
      setIsLoading(true);
      fetch(`${baseUrl}/v1/payment-link/${params.paymentLink}`)
        .then((res) => res.json())
        .then((res) => {
          if (res.status == 404) {
            navigate("/notfound");
            return;
          }
          setPaymentlinkInfo(res);
          if (res?.prepayOptions?.collectAddress) {
            REQUIRED_FIELDS.push("address");
          }
          if (res?.linkType == "DONATION") {
            REQUIRED_FIELDS.push("fiatAmount");
            REQUIRED_FIELDS.push("fiatCurrency");
          }
          if (res?.prepayOptions?.collectPhone) {
            REQUIRED_FIELDS.push("phone");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("ERRRRR", err);
        });
    }
  }, [params]);

  useEffect(() => {
    const hasErrors = REQUIRED_FIELDS.some(
      (field) => !validateRequiredFields(field, formdata?.[field] ?? "")
    );
    setDisableBtn(hasErrors);
  }, [formdata]);
  const validateRequiredFields = (field: string, value: string) => {
    let isValid = true;
    switch (field) {
      case "email":
        isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        break;
      case "fullname":
      case "address":
        isValid = value.trim() !== "";
        break;
      case "phone":
        isValid = isPhoneValid(value);
        break;
      case "fiatCurrency":
        isValid = FIAT_CURRENCY.filter((m) => m.value == value).length > 0;
        break;
      case "fiatAmount":
        isValid = Number(value ?? 0) > 0;
        break;
      default:
        break;
    }
    return isValid;
  };
  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target;
    // Validate inputs
    let error = "";
    switch (id) {
      case "email":
        error = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? "Invalid email address"
          : "";
        break;
      case "fullname":
        error = value.trim() === "" ? "Full name is required" : "";
        break;
      case "address":
        error = value.trim() === "" ? "Address is required" : "";
        break;
      case "phone":
        error = !isPhoneValid(value) ? "Invalid phone number" : "";
        break;
      case "fiatCurrency":
        error =
          FIAT_CURRENCY.filter((m) => m.value == value).length > 0
            ? ""
            : "Invalid currency";
        break;
      case "fiatAmount":
        error = isNaN(value) || value.trim() == "" ? "Invalid amount" : "";
        break;
      default:
        break;
    }
    setErrors((prev: any) => ({ ...prev, [id]: error }));
    setFormData((prv: any) => ({ ...prv, [id]: value }));
  };
  const payment = useRef(false);

  async function onSuccess(data: any) {
    console.log("onSuccess", data);
    payment.current = true;
  }
  const onClose = (data: any) => {
    console.error("onClose", data);
    merchantReference.current = generateRandomReference();
    if (payment.current) {
      navigate(`/${params.paymentLink}/success`, {
        state: {
          origin: params.paymentLink,
          postpayAction: paymentlinkInfo.postpayAction,
        },
      });
    }
  };

  function onError(data: any) {
    console.error("onError", data);
  }
  //   {
  //     "createdAt": "2024-01-27T12:35:17.101Z",
  //     "updatedAt": "2024-01-27T12:42:35.066Z",
  //     "deletedAt": null,
  //     "id": "81524abe-d973-4c8b-b7d1-618df327ae07",
  //     "slug": "plqwRzdgfv",
  //     "url": "https://konnadex.com/plqwRzdgfv",
  //     "isActive": true,
  //     "visitedCount": 36,
  //     "paymentCount": 0,
  //     "title": "My Pure Hair Brazilian",
  //     "description": "This is a test payment link for my business",
  //     "integratorId": "65b4a209e567b106be31ccf8",
  //     "fiatAmount": "60000",
  //     "fiatCurrency": "NGN",
  //     "prepayOptions": {
  //         "collectAddress": true,
  //         "collectPhone": true
  //     },
  //     "postpayAction": {
  //         "successMessage": "Thank you for your payment",
  //         "redirect": true,
  //         "redirectUrl": "https://konna.io",
  //         "showConfirmationPage": true
  //     },
  //     "cart": null,
  //     "__type": "PaymentLink",
  //     "apiKey": "KDX_PUBK_TEST_tjrBVc5XB9gC6riYpjYU5DpQWtg7taaNnCVqQVEukzYhTEjO"
  // }
  if (loading) {
    return <AppLoader />;
  }

  if (paymentlinkInfo) {
    return (
      <>
        <Box>
          {/* <Header
          title={paymentlinkInfo.title}
          description={paymentlinkInfo.description}
        /> */}
          <Flex
            flexDirection={["column", "column", "row"]}
            // justifyContent="space-evenly"
          >
            <Box
              bg={"#1540C2"}
              mb={["30px", "30px", 0]}
              width={["100%", "100%", "50%"]}
              minH={["100%", "100%", "100vh"]}
            >
              <Header
                title={paymentlinkInfo.title}
                description={paymentlinkInfo.description}
              />
              {/* <Card>
              <CardHeader>
                <Heading size="md">Order Details</Heading>
              </CardHeader>
              <CardBody>
                <Stack divider={<StackDivider />} spacing="4">
                  {["sm", "md", "lg"].map((size) => (
                    <Box key={size}>
                      <Heading size="xs" textTransform="uppercase">
                        Summary
                      </Heading>
                      <Text pt="2" fontSize="sm">
                        View a summary of all your clients over the last month.
                      </Text>
                    </Box>
                  ))}
                </Stack>
              </CardBody>
            </Card> */}
            </Box>
            <Flex
              flexDirection={"row"}
              width={["100%", "100%", "50%"]}
              mb={["30px", "30px", 0]}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box width={["90%", "90%", "80%", "80%", "60%"]}>
                {paymentlinkInfo.linkType == "STANDARD" && (
                  <Flex
                    flexDirection={"column"}
                    justifyContent="space-between"
                    mb="20px"
                    borderRadius="8px"
                    pt={["5", "10"]}
                    pb={["5", "10"]}
                  >
                    <Box>You are paying</Box>
                    <Heading size={["sm", "md"]}>
                      {formatCurrency(
                        paymentlinkInfo.fiatCurrency,
                        paymentlinkInfo.fiatAmount
                      )}
                    </Heading>
                  </Flex>
                )}
                <Stack spacing="5">
                  {paymentlinkInfo.linkType == "DONATION" && (
                    <>
                      <FormControl isRequired isInvalid={!!errors.fiatAmount}>
                        <FormLabel>Donation Amount</FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <MoneyIcon />
                          </InputLeftElement>
                          <Input
                            onChange={handleInputChange}
                            type="number"
                            id="fiatAmount"
                            placeholder="Amount"
                          />
                        </InputGroup>

                        {errors.fiatAmount && (
                          <FormErrorMessage>
                            {errors.fiatAmount}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                      <FormControl isRequired isInvalid={!!errors.fiatCurrency}>
                        <FormLabel>Fiat Currency</FormLabel>
                        <InputGroup>
                          <Select
                            id="fiatCurrency"
                            onChange={handleInputChange}
                            placeholder="Fiat Currency"
                          >
                            {FIAT_CURRENCY.map((x) => (
                              <option value={x.value}>{x.label}</option>
                            ))}
                          </Select>
                        </InputGroup>

                        {errors.fiatCurrency && (
                          <FormErrorMessage>
                            {errors.fiatCurrency}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </>
                  )}
                  <FormControl isRequired isInvalid={!!errors.fullname}>
                    <FormLabel>Full name</FormLabel>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <UserIcon />
                      </InputLeftElement>
                      <Input
                        onChange={handleInputChange}
                        type="text"
                        id="fullname"
                        placeholder="First name Last name"
                      />
                    </InputGroup>

                    {errors.fullname && (
                      <FormErrorMessage>{errors.fullname}</FormErrorMessage>
                    )}
                  </FormControl>
                  <Stack direction={["column", "row"]}>
                    <FormControl isRequired isInvalid={!!errors.email}>
                      <FormLabel>Email</FormLabel>
                      <InputGroup>
                        <InputLeftElement pointerEvents="none">
                          <MessageIcon />
                        </InputLeftElement>
                        <Input
                          onChange={handleInputChange}
                          type="email"
                          id="email"
                          placeholder="Email"
                          isInvalid={!!errors.email}
                        />
                      </InputGroup>

                      {errors.email && (
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                      )}
                    </FormControl>
                    {paymentlinkInfo.prepayOptions?.collectPhone && (
                      <FormControl isRequired isInvalid={!!errors.phone}>
                        <FormLabel>Phone</FormLabel>

                        <PhoneNumber
                          value={formdata?.["phone"]}
                          onChange={handleInputChange as any}
                        />

                        {errors.phone && (
                          <FormErrorMessage>{errors.phone}</FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Stack>
                  {paymentlinkInfo.prepayOptions?.collectAddress && (
                    <FormControl isRequired isInvalid={!!errors.address}>
                      <FormLabel>Address</FormLabel>
                      <InputGroup>
                        <InputLeftElement pointerEvents="none">
                          <AddressIcon />
                        </InputLeftElement>
                        <Input
                          type="text"
                          onChange={handleInputChange}
                          id="address"
                          placeholder="Street Address"
                        />
                      </InputGroup>
                      {errors.address && (
                        <FormErrorMessage>{errors.address}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                  <FormControl isInvalid={!!errors.address}>
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      onChange={handleInputChange}
                      id="description"
                      placeholder="Description"
                    />
                  </FormControl>
                  <KonnadexCheckoutButton
                    apiKey={paymentlinkInfo.apiKey}
                    reference={merchantReference.current}
                    env={
                      (params.paymentLink || "").split("_").length > 1
                        ? "DEVELOPMENT"
                        : "PRODUCTION"
                    }
                    amount={paymentlinkInfo.fiatAmount ?? formdata?.fiatAmount}
                    currency={
                      paymentlinkInfo.fiatCurrency ?? formdata?.fiatCurrency
                    }
                    metadata={filterMetadata(formdata)}
                    PayNowBtnComponent={Button}
                    isDisabled={loading || disableBtn}
                    onClose={onClose}
                    onError={onError}
                    onSuccess={onSuccess}
                    text="Process Payment"
                    btnProps={{
                      loadingText: "Processing..",
                      fontWeight: "normal",
                      color: "#fff",
                      // variant: "outline",
                      width: "100%",
                      className: "sm_small",
                      backgroundColor:
                        loading && disableBtn ? "blue.200" : "#1540C2",
                    }}
                  />
                  <Footer />
                </Stack>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </>
    );
  }
  return null;
}
