import { Icon } from "@chakra-ui/react";
export function MoneyIcon(props: any) {
  return (
    <Icon
      //   height={["200px", "400px"]}
      //   width={["200px", "400px"]}
      viewBox="0 0 492.308 492.308"
      {...props}
    >
      <g>
        <g>
          <path
            d="M459.649,85.072H32.659C14.649,85.072,0,99.716,0,117.726v256.846c0,18.01,14.649,32.663,32.659,32.663h426.99
			c18.01,0,32.659-14.654,32.659-32.663V117.726C492.308,99.716,477.659,85.072,459.649,85.072z M472.615,374.572
			c0,7.154-5.817,12.971-12.966,12.971H32.659c-7.149,0-12.966-5.817-12.966-12.971V117.726c0-7.144,5.817-12.962,12.966-12.962
			h426.99c7.149,0,12.966,5.817,12.966,12.962V374.572z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M413.538,186.649c-12.577,0-22.813-10.231-22.813-22.808v-9.846H101.582v9.846c0,12.577-10.236,22.808-22.813,22.808
			h-9.846v119h9.846c12.577,0,22.813,10.24,22.813,22.817v9.846h289.144v-9.846c0-12.577,10.236-22.817,22.813-22.817h9.846v-119
			H413.538z M403.692,287.111c-15.543,3.702-27.808,15.962-31.51,31.51H120.125c-3.702-15.548-15.966-27.808-31.51-31.51v-81.923
			c15.543-3.702,27.808-15.962,31.51-31.5h252.058c3.702,15.539,15.966,27.798,31.51,31.5V287.111z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M246.154,204.341c-23.053,0-41.808,18.76-41.808,41.808c0,23.048,18.755,41.808,41.808,41.808
			c23.053,0,41.808-18.76,41.808-41.808C287.962,223.101,269.207,204.341,246.154,204.341z M246.154,268.264
			c-12.192,0-22.115-9.923-22.115-22.115c0-12.192,9.923-22.115,22.115-22.115s22.115,9.923,22.115,22.115
			C268.269,258.341,258.346,268.264,246.154,268.264z"
          />
        </g>
      </g>
    </Icon>
  );
}

// <?xml version="1.0" encoding="iso-8859-1"?>
// <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
// <svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
// 	 xml:space="preserve">

// </svg>
