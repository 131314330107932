import { Box, Heading, Text } from "@chakra-ui/react";

export function Header({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <Box  as="section">
      <Box
        as="section"
        color="gray.300"
        bg="#1540C2"
        pt={["10px","50px"]}
        pb="18px"
        px="32px"
      >
        <Heading fontWeight="bold" fontSize={["2xl", "2xl", "3xl"]}>
          {title}
        </Heading>
        <Text fontWeight="medium" fontSize={["sm", "lg", "2lg"]} pt="4">
          {description}
        </Text>
      </Box>
    </Box>
  );
}
