import { useEffect } from "react";
import useScript from "./konna-script";
import { Config, NestedFuncs } from "./types";

declare const window: Window &
  typeof globalThis & {
    KonnadexCheckout: (config: Config) => NestedFuncs;
  };

const useKonnadexCheckout = (props: Config): [Function, boolean] => {
  const [loaded, error] = useScript();

  useEffect(() => {
    if (error) throw new Error("Unable to load KonnadexCheckout inline script");
  }, [error]);

  function initKonnadexCheckout() {
    if (error) throw new Error("Unable to load inline script");
    const pay: NestedFuncs =
      window.KonnadexCheckout && new (window.KonnadexCheckout as any)(props);
    pay.initializePay();
  }

  return [initKonnadexCheckout, loaded];
};

export default useKonnadexCheckout;
