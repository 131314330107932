export const CURRENCY_LOCALE: Record<string, string> = {
  USD: "en-US-POSIX",
  NGN: "en-NG",
  GHS: "en-GH",
  GBP: "en-GB",
  KES: "en-KE",
  ZAR: "en-ZA",
  JYP: "ja-JP",
  XAF: "lg-CG",
  X0F: "ee-TG",
  EGP: "ar-EG",
};

export enum STATUS {
  SUCCESS = "success",
  ERROR = "error",
}
