import { CURRENCY_LOCALE } from "./constants";

export function generateRandomReference() {
  const prefix = "PLK_";
  const timestamp = Date.now().toString(36);
  const randomSuffix = Math.random().toString(36).substring(2, 10); // Generate a random string
  const randomReference = `${prefix}${randomSuffix}${timestamp}`;
  return randomReference;
}

export function formatCurrency(currency: string, amount: any) {
  return new Intl.NumberFormat(CURRENCY_LOCALE?.[currency], {
    style: "currency",
    currency: currency,
  }).format(amount);
}
