import { useEffect, useState } from "react";

type ScriptStatusType = {
  loaded: boolean;
  error: boolean;
};

const id = "konnnadexPayScript";

export default function useScript(): boolean[] {
  const src =
    "https://cdn.jsdelivr.net/gh/konnadex/konnadex-pay@main/index.min.js";
  const [state, setState] = useState<ScriptStatusType>({
    loaded: false,
    error: false,
  });

  useEffect(() => {
    const scriptTag = document.getElementById(id);
    const scriptSrc = scriptTag && scriptTag.getAttribute("src");

    if (scriptSrc)
      return setState({
        loaded: true,
        error: false,
      });

    const script = document.createElement("script");
    script.id = id;
    script.src = src;
    script.async = true;

    const onScriptLoad = () => {
      setState({
        loaded: true,
        error: false,
      });
    };

    const onScriptError = () => {
      script.remove();
      setState({
        loaded: false,
        error: true,
      });
    };

    script.addEventListener("load", onScriptLoad);
    script.addEventListener("complete", onScriptLoad);
    script.addEventListener("error", onScriptError);

    document.body.appendChild(script);

    return () => {
      script.removeEventListener("load", onScriptLoad);
      script.removeEventListener("complete", onScriptLoad);
      script.removeEventListener("error", onScriptError);
    };
  }, []);

  return [state.loaded, state.error];
}
