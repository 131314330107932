import { Box, Flex, Heading } from "@chakra-ui/react";
import { NotFoundIcon } from "../../icons";

export default function NotFoundPage() {
  return (
    <Box>
      <Flex
        flexDirection="column"
        justifyContent="center"
        height={"100vh"}
        alignItems="center"
      >
        <Box>
          <NotFoundIcon />
        </Box>
        <Box mt="30px">
          <Heading color="gray.500" size={["md","lg"]}>
            That page doesn't exist
          </Heading>
        </Box>
      </Flex>
    </Box>
  );
}
